import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {AngularFireAuth} from 'angularfire2/auth';
import {from} from "rxjs";
import {switchMap} from "rxjs/operators";
import swal from "sweetalert2";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private afAuth: AngularFireAuth) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // 로그인한 유저장보가 없을경우 바이패기 하기위한 부분
    if (!this.afAuth.auth.currentUser) {
      return next.handle(req);
    }

    return from(this.afAuth.auth.currentUser.getIdToken(true)).pipe(
      switchMap(token => {

        req = req.clone({
          setHeaders: {
            Authorization: `${token}`
          }
        });

        return next.handle(req).do((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        }, (err: any) => {

          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.afAuth.auth.signOut();
              this.router.navigate(['/auth/signin'], {skipLocationChange: true});
            }
          }
        });
      })
    );

  }

}
