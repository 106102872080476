// role 상태 체크하는 서비스
import {Injectable} from "@angular/core";

@Injectable()
export class StatusService {

  status = [];

  admin = 'admin.treasurney.com';
  dev = 'dev.admin.treasurney.com';
  local = 'localhost';

  constructor() {
  }


}
