import {Injectable} from "@angular/core";
import {UserModel} from "./_models/user.model";
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import {AngularFireAuth} from "angularfire2/auth";
import {UserService} from "./_services/user.service";
import {Observable} from "rxjs";
import swal from "sweetalert2";
import {StatusService} from "./_services/status.service";
import {HttpErrorResponse} from "@angular/common/http";

@Injectable()
export class AuthResolver implements Resolve<UserModel> {

  allowedRoles = ['ADMIN', 'CREATOR'];
  status: boolean;
  status1 = ['ADMIN', 'CREATOR'];
  status2 = ['ADMIN'];

  constructor(private router: Router, private afAuth: AngularFireAuth, private userService: UserService, private statusService: StatusService) {
  }

  // 사용자 권한에 따라서 페이징 막기. 권한이 admin이 아니면 진입불가
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return this.userService.getMeRole().map(user => {

      switch (window.location.hostname) {
        case 'localhost':
          this.status = user.some(item => this.status1.includes(item.id) && this.status2.includes(item.id));
          break;
        case 'admin.treasurney.com':
          this.status = user.some(item => this.status1.includes(item.id) && this.status2.includes(item.id));
          console.log(this.status);
          break;
      }

      if (user) {
        // console.log(user);
        const roleCheck = user.some(item => this.allowedRoles.includes(item.id));

        if (!roleCheck) {
          swal(
            '',
            '허가되지 않는 사용자입니다.',
            'error'
          );
          return this.router.navigate(['/auth/signin']);
        }

        return user;
      }

      this.router.navigate(['/auth/signin']);
      return null;
    }, (err: any) => {
      console.error(err);
      if (err instanceof HttpErrorResponse) {
        if (err.status === 404) {
          this.afAuth.auth.signOut();
          this.router.navigate(['/auth/signin']);
        }
      }
    });
  }

}
