// 사용자 서비스
import {Injectable} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {LocalApiService} from "./local-api.service";
import {RolesModel} from "../_models/roles.model";
import {UserModel} from "../_models/user.model";
import {AngularFireAuth} from "angularfire2/auth";
import {Observable} from "rxjs";
import {ListResponseModel} from "../_models/list-response.model";

@Injectable()
export class UserService {

  constructor(private api: LocalApiService, private https: HttpClient, private afAuth: AngularFireAuth) {
  }

  getMeRole(): Observable<UserModel[]> {
    const uid = this.afAuth.auth.currentUser.uid;

    return this.https.get<UserModel[]>(this.api.defaultApiAddress + '/users/' + uid + '/roles', {});
  }

  readUsersById(): Promise<UserModel> {
    const uid = this.afAuth.auth.currentUser.uid;

    return this.https.get<UserModel>(`${this.api.defaultApiAddress}/users/` + uid, {headers: this.api.defaultHeaders}).toPromise();
  }

  readUserId(id: string): Promise<UserModel> {
    return this.https.get<UserModel>(`${this.api.defaultApiAddress}/users/` + id, {headers: this.api.defaultHeaders}).toPromise();
  }


  /**
   * 사용자 등록 api
   */
  createUsers(users: UserModel): Promise<UserModel> {
    return this.https.post<UserModel>(`${this.api.defaultApiAddress}/users`, users, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 사용자 조회 api
   */
  readUsers(): Promise<ListResponseModel<UserModel>> {
    const params = new HttpParams()
      .append('page', '0')
      .append('rpp', '99999');

    return this.https.get<ListResponseModel<UserModel>>(`${this.api.defaultApiAddress}/users`, {headers: this.api.defaultHeaders, params: params}).toPromise();
  }

  /**
   * 사용자 이메일로 조회
   */
  readUsersSearchEmail(email: string) {
    const params = new HttpParams()
        .append('email', email);

    return this.https.get<ListResponseModel<UserModel>>(`${this.api.defaultApiAddress}/users`, {headers: this.api.defaultHeaders, params: params}).toPromise();
  }

  /**
   * 사용자 등록 및 수정 api
   */
  updateUsers(userId: string, user: UserModel): Promise<UserModel> {
    return this.https.put<UserModel>(`${this.api.defaultApiAddress}/users/${userId}`, user, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 사용자 삭제
   */
  deleteUserById(userId: string): Promise<UserModel> {
    return this.https.delete<UserModel>(`${this.api.defaultApiAddress}/users/${userId}`, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 권한 추가 api
   */
  createRoles(roles: RolesModel): Promise<RolesModel> {
    return this.https.post<RolesModel>(`${this.api.defaultApiAddress}/roles`, roles, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 권한 검색 api
   */
  readRoles(): Promise<RolesModel[]> {
    return this.https.get<RolesModel[]>(`${this.api.defaultApiAddress}/roles`, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 권한 ID로 조회 api
   */
  readRolesById(id: string): Promise<RolesModel[]> {
    return this.https.get<RolesModel[]>(`${this.api.defaultApiAddress}/roles/${id}`, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 권한 ID로 삭제 api
   */
  deleteRolesById(id: string): Promise<RolesModel> {
    return this.https.delete<RolesModel>(`${this.api.defaultApiAddress}/roles/${id}`, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 사용자 ID에 대한 권한 조회 api
   */
  readRoleUserById(id: string): Promise<RolesModel[]> {
    return this.https.get<RolesModel[]>(`${this.api.defaultApiAddress}/users/${id}/roles`, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 사용자 ID에 대한 권한 추가
   */
  creatreRoleUserById(id: string, role: any): Promise<RolesModel> {
    return this.https.post<RolesModel>(`${this.api.defaultApiAddress}/users/${id}/roles`, role, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 사용자 ID에 대한 권한 수정
   */
  updateRoleUserById(id: string, role: any): Promise<RolesModel> {
    return this.https.put<RolesModel>(`${this.api.defaultApiAddress}/users/${id}/roles`, role, {headers: this.api.defaultHeaders}).toPromise();
  }

  /**
   * 사용자 ID로 강제 토큰 취소 api
   */
  readUserRevoke(userId: string): Promise<UserModel> {
    return this.https.get<UserModel>(`${this.api.defaultApiAddress}/users/${userId}/revoke`, {headers: this.api.defaultHeaders}).toPromise();
  }
}
