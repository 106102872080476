import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './layout/admin/admin.component';
import {AuthComponent} from './layout/auth/auth.component';
import {AuthResolver} from "./auth-resolver";
import {AuthGuardService} from "./_services/auth-guard.service";

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    resolve: {user: AuthResolver},
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'event',
        pathMatch: 'full'
      },
      {
        path: 'groups',
        loadChildren: './group/group.module#GroupModule'
      },
      {
        path: 'users',
        loadChildren: './users/users.module#UsersModule'
      },
      {
        path: 'roles',
        loadChildren: './roles/roles.module#RolesModule'
      },
      {
        path: 'event',
        loadChildren: './event/event.module#EventModule'
      },
      {
        path: 'treasure-users-info',
        loadChildren: './treasure-users-info/treasure-users-info.module#TreasureUsersInfoModule'
      },
      {
        path: 'purchase-histories',
        loadChildren: './purchase-histories/purchase-histories.module#PurchaseHistoriesModule'
      },
      {
        path: 'feeds',
        loadChildren: './feeds/feeds.module#FeedsModule'
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './session/auth/auth.module#AuthModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
