// api 서비스
import {Injectable} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {AngularFireAuth} from "angularfire2/auth";
import * as firebase from "firebase/app";
import {Router} from "@angular/router";

@Injectable()
export class LocalApiService {
  defaultApiAddress: string;
  defaultHeaders: HttpHeaders;

  constructor(private afAuth: AngularFireAuth, private router: Router) {

    switch (window.location.hostname) {
      case 'dev.admin.treasurney.com':
        this.defaultApiAddress = '//dev.api.treasurney.com';
        break;
      case 'localhost':
        this.defaultApiAddress = '//dev.api.treasurney.com';
        break;
      default:
        this.defaultApiAddress = '//api.treasurney.com';
        break;
    }

    this.defaultHeaders = new HttpHeaders();

    const user = firebase.auth().currentUser;

    if (user === null) {
      return;
    } else {
      user.getIdToken(true).then(token => {
        this.defaultHeaders.append('Authorization', token);
        this.defaultHeaders.append('Content-Type', 'application/json');
        this.defaultHeaders.append('Access-Control-Allow-Origin', '*');
      });
    }
  }
}
